// types
import type { ModuleOptions } from '@nuxt/schema'

// toasts
import Vue3Toastify, { toast } from 'vue3-toastify'
import 'vue3-toastify/dist/index.css'

// actor core
import { useActorCore } from './core/actor'

// auth
import { useAuth } from './auth/auth'

// fetch instances
import { createExamsApiFetchInstance } from './fetch-instances/examApiFetchInstance'
import { createOrbitApiFetchInstance } from './fetch-instances/orbitApiFetchInstance'

// fetch repository
import { apiFetchRepository } from './repository/apiFetchRepository'

// middleware
import authMiddleware from './middleware/auth'

// nuxt
import {
  addRouteMiddleware,
  defineNuxtPlugin,
  useNuxtApp,
  useRuntimeConfig
} from '#app'

export default defineNuxtPlugin({
  name: '@revolutionprep/actor',
  setup () {
    // nuxt app
    const nuxtApp = useNuxtApp()

    // runtime config
    const publicRuntimeConfig = useRuntimeConfig().public
    const actorRuntimeConfig = publicRuntimeConfig.actor as ModuleOptions

    // fetch instances
    const examsFetchInstance =
      createExamsApiFetchInstance(publicRuntimeConfig)
    const orbitFetchInstance =
      createOrbitApiFetchInstance(publicRuntimeConfig)

    // actor module core
    const core = useActorCore(actorRuntimeConfig)
    // authentication
    const auth = useAuth()

    // authentication route middleware
    addRouteMiddleware('auth', authMiddleware, { global: true })

    // use toasts
    nuxtApp.vueApp.use(Vue3Toastify, {
      theme: 'colored'
    })

    return {
      provide: {
        // provide actor
        actor: {
          auth,
          core
        },
        // toast notifications
        toast,
        // api fetch instances
        examsApiFetch: examsFetchInstance,
        orbitApiFetch: orbitFetchInstance,
        // provide orbit resources
        countries: apiFetchRepository(orbitFetchInstance, 'v2/countries'),
        courses: apiFetchRepository(orbitFetchInstance, 'v2/courses'),
        departments: apiFetchRepository(orbitFetchInstance, 'v2/departments'),
        enrollments: apiFetchRepository(orbitFetchInstance, 'v2/enrollments'),
        events: apiFetchRepository(orbitFetchInstance, 'v2/events'),
        gradeLevels: apiFetchRepository(orbitFetchInstance, 'v2/grade_levels'),
        leadSources: apiFetchRepository(orbitFetchInstance, 'v2/lead_sources'),
        parents: apiFetchRepository(orbitFetchInstance, 'v2/parents'),
        pendingSchools: apiFetchRepository(orbitFetchInstance, 'v2/pending_schools'),
        properties: apiFetchRepository(orbitFetchInstance, 'v2/properties'),
        propertyRecords: apiFetchRepository(orbitFetchInstance, 'v2/property_records'),
        roles: apiFetchRepository(orbitFetchInstance, 'v2/roles'),
        states: apiFetchRepository(orbitFetchInstance, 'v2/states'),
        students: apiFetchRepository(orbitFetchInstance, 'v2/students'),
        studyAreas: apiFetchRepository(orbitFetchInstance, 'v2/study_areas'),
        studyAreaSnapshots: apiFetchRepository(orbitFetchInstance, 'v2/study_area_snapshots'),
        subjects: apiFetchRepository(orbitFetchInstance, 'v2/subjects'),
        testDates: apiFetchRepository(orbitFetchInstance, 'v2/test_dates'),
        tiers: apiFetchRepository(orbitFetchInstance, 'v2/tiers'),
        timeZones: apiFetchRepository(orbitFetchInstance, 'v2/time_zones'),
        tutoringHistories: apiFetchRepository(orbitFetchInstance, 'v2/tutoring_histories'),
        tutorPackageMatches: apiFetchRepository(orbitFetchInstance, 'v2/tutor_package_matches'),
        tutorPackages: apiFetchRepository(orbitFetchInstance, 'v2/tutor_packages'),
        tutorPackageTranscripts: apiFetchRepository(orbitFetchInstance, 'v2/tutor_package_transcripts'),
        tutors: apiFetchRepository(orbitFetchInstance, 'v2/tutors'),
        // provide exams resources
        exams: apiFetchRepository(examsFetchInstance, 'api/exams'),
        transcripts: apiFetchRepository(examsFetchInstance, 'api/transcripts'),
        transcriptsSummary: apiFetchRepository(examsFetchInstance, 'api/transcripts/summary')
      }
    }
  }
})
