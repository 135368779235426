// types
import type { Enrollment } from '@revolutionprep/types'

// stores
import { useTrialStore } from '@/store/trial'

export function useTrial () {
  /**
 * stores
 * ==================================================================
 */
  const trialStore = useTrialStore()
  const {
    isActiveTrial,
    activeTrialDays
  } = storeToRefs(trialStore)

  /**
 * methods
 * ==================================================================
 */
  function hasActiveTrial (enrollments: Array<Enrollment>) {
    const enrollment = enrollments.find(
      enrollment => enrollment?.brand?.name === 'Trial'
    )

    if (enrollment) {
      activeTrialDays.value = _calculateTrialExpires(
        enrollment?.startsAt, enrollment.brand?.forceScheduleByAfterDays
      )

      isActiveTrial.value = activeTrialDays.value > 0
    } else {
      isActiveTrial.value = false
    }
  }

  function _calculateTrialExpires (
    start: any,
    forceScheduleByAfterDays?: number
  ) {
    if (start && forceScheduleByAfterDays) {
      const startDate = new Date(start)
      const today = new Date()

      // Add forceScheduleByAfterDays to startDate
      const trialExpiresDate = new Date(startDate)
      trialExpiresDate.setDate(
        trialExpiresDate.getDate() + forceScheduleByAfterDays
      )

      // Calculate the difference in days between trialExpiresDate and today
      const timeDifference = trialExpiresDate.getTime() - today.getTime()
      const daysUntilTrialExpires = Math.ceil(
        timeDifference / (1000 * 3600 * 24)
      )

      return daysUntilTrialExpires
    }

    return 0
  }

  return { hasActiveTrial }
}
