<script setup lang="ts">
// types
import type { FetchError } from 'ofetch'

// composables
import { usePusher } from '@/composables/pusher'
import { useTrial } from '@/composables/trial'

/**
 * nuxt app
 * ==================================================================
 */
const { $actor, $pwa } = useNuxtApp()

/**
 * route & router
 * ==================================================================
 */
const route = useRoute()
const router = useRouter()

/**
 * stores
 * ==================================================================
 */
const enrollmentStore = useEnrollmentStore()
const { enrollments } = storeToRefs(enrollmentStore)

/**
 * pusher
 * ==================================================================
 */
const { doHandleError } = useErrorHandler()

const {
  initializePusher,
  subscribeToGlobalChannels
} = usePusher()

const { hasActiveTrial } = useTrial()

/**
 * actor
 * ==================================================================
 */
const actor = computed(() => {
  return $actor.core.actor.value
})

const isLoggedIn = computed(() => {
  return $actor.core.isLoggedIn.value
})

const userId = computed(() => {
  return actor.value?.userId
})

/**
 * snackbar refresh to update
 * ==================================================================
 */
const showSnackbar = ref(false)

function doToggleSnackbar (isOpen: boolean) {
  showSnackbar.value = isOpen
}

/**
 * authentication
 * ==================================================================
 */
try {
  if (!route.meta.noAuthRequired) {
    await $actor.core.doLogin()
  }
} catch (error) {
  await router.push('/login')
}

/**
 * watcher
 * ==================================================================
 */
watch(
  actor,
  () => {
    if (actor.value) {
      initializePusher()
      if (userId.value) {
        subscribeToGlobalChannels(userId.value)
      }
    }
  },
  { immediate: true }
)

watch(
  isLoggedIn,
  async () => {
    if (!isLoggedIn.value) {
      return
    }
    try {
      await enrollmentStore.index({
        params: {
          active: true,
          include: 'brand.userflow_token,course,tutors'
        }
      })

      hasActiveTrial(enrollments.value)
    } catch (error) {
      doHandleError(error as FetchError)
    }
  },
  { immediate: true }
)

/**
 * lifecycle hook
 * ==================================================================
 */
onMounted(() => {
  if ($pwa?.needRefresh) {
    showSnackbar.value = true
  }
})
</script>

<template>
  <NuxtLayout>
    <NuxtPwaManifest />
    <NuxtPage />
    <lazy-r-snackbar-refresh-to-update
      :show="showSnackbar"
      @toggle-refresh-snackbar="doToggleSnackbar"
    />
  </NuxtLayout>
</template>

<style lang="scss">
@use './src/assets/scss/settings';
</style>
