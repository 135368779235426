import { default as availabilityYMsA7lxMWzMeta } from "/vercel/path0/apps/student/src/pages/bright-horizons/enrollment-wizard/[tutorPackageTranscriptId]/availability.vue?macro=true";
import { default as completeyVKWSLYEyXMeta } from "/vercel/path0/apps/student/src/pages/bright-horizons/enrollment-wizard/[tutorPackageTranscriptId]/complete.vue?macro=true";
import { default as indexiTHLjm61MQMeta } from "/vercel/path0/apps/student/src/pages/bright-horizons/enrollment-wizard/[tutorPackageTranscriptId]/index.vue?macro=true";
import { default as subjectDQ8HcoxvHbMeta } from "/vercel/path0/apps/student/src/pages/bright-horizons/enrollment-wizard/[tutorPackageTranscriptId]/subject.vue?macro=true";
import { default as submitn47fIJwWWIMeta } from "/vercel/path0/apps/student/src/pages/bright-horizons/enrollment-wizard/[tutorPackageTranscriptId]/submit.vue?macro=true";
import { default as tutordyUZEsRV2FMeta } from "/vercel/path0/apps/student/src/pages/bright-horizons/enrollment-wizard/[tutorPackageTranscriptId]/tutor.vue?macro=true";
import { default as indexQgbNHFFVWSMeta } from "/vercel/path0/apps/student/src/pages/exams/index.vue?macro=true";
import { default as proctorEC288oreBTMeta } from "/vercel/path0/apps/student/src/pages/exams/proctor.vue?macro=true";
import { default as helptrLlr0zfXMMeta } from "/vercel/path0/apps/student/src/pages/help.vue?macro=true";
import { default as indexW3BdLl1coVMeta } from "/vercel/path0/apps/student/src/pages/index.vue?macro=true";
import { default as login2u7ddXs1S1Meta } from "/vercel/path0/apps/student/src/pages/login.vue?macro=true";
import { default as materialskh63ZSbPfcMeta } from "/vercel/path0/apps/student/src/pages/materials.vue?macro=true";
import { default as indexlu1AULD9ZUMeta } from "/vercel/path0/apps/student/src/pages/my-calendar/index.vue?macro=true";
import { default as indexhJVoXQ6V7uMeta } from "/vercel/path0/apps/student/src/pages/profile/index.vue?macro=true";
import { default as availabilityDGGL2HfUouMeta } from "/vercel/path0/apps/student/src/pages/schedule-sessions/[enrollmentId]/availability.vue?macro=true";
import { default as confirmMdCO0i2YDNMeta } from "/vercel/path0/apps/student/src/pages/schedule-sessions/[enrollmentId]/confirm.vue?macro=true";
import { default as subjectsGXRGfU3SaMeta } from "/vercel/path0/apps/student/src/pages/schedule-sessions/[enrollmentId]/subject.vue?macro=true";
import { default as indexl7h7coric4Meta } from "/vercel/path0/apps/student/src/pages/schedule-sessions/index.vue?macro=true";
import { default as subscribeejtK1G7E6cMeta } from "/vercel/path0/apps/student/src/pages/schedule/subscribe.vue?macro=true";
import { default as confirmAJtXXpR2vQMeta } from "/vercel/path0/apps/student/src/pages/schedule/tutor/[tutorId]/enrollment/[enrollmentId]/confirm.vue?macro=true";
import { default as indexfEF1qEF4MhMeta } from "/vercel/path0/apps/student/src/pages/schedule/tutor/[tutorId]/enrollment/[enrollmentId]/index.vue?macro=true";
import { default as successfkQ9CVVJYvMeta } from "/vercel/path0/apps/student/src/pages/schedule/tutor/[tutorId]/enrollment/[enrollmentId]/success.vue?macro=true";
import { default as indexdclNCTtSA7Meta } from "/vercel/path0/apps/student/src/pages/schedule/tutor/index.vue?macro=true";
import { default as tutorV8OLNODAhgMeta } from "/vercel/path0/apps/student/src/pages/schedule/tutor.vue?macro=true";
import { default as secretOhvL8TPAy0Meta } from "/vercel/path0/apps/student/src/pages/secret.vue?macro=true";
import { default as study_45areasKryxwKFdn6Meta } from "/vercel/path0/apps/student/src/pages/study-areas.vue?macro=true";
import { default as availabilitytXLplHGWnCMeta } from "/vercel/path0/apps/student/src/pages/tutor-matching/[matchId]/availability.vue?macro=true";
import { default as completeAMpuJxapdQMeta } from "/vercel/path0/apps/student/src/pages/tutor-matching/[matchId]/complete.vue?macro=true";
import { default as indexU81GS62osBMeta } from "/vercel/path0/apps/student/src/pages/tutor-matching/[matchId]/index.vue?macro=true";
import { default as submit17T7i42Ge0Meta } from "/vercel/path0/apps/student/src/pages/tutor-matching/[matchId]/submit.vue?macro=true";
import { default as indexIdhUawRk3EMeta } from "/vercel/path0/apps/student/src/pages/videos/[subject]/index.vue?macro=true";
import { default as _91id_93dycxtJdy2yMeta } from "/vercel/path0/apps/student/src/pages/videos/[subject]/watch/[id].vue?macro=true";
import { default as indexA65heq9koXMeta } from "/vercel/path0/apps/student/src/pages/videos/index.vue?macro=true";
export default [
  {
    name: availabilityYMsA7lxMWzMeta?.name ?? "bright-horizons-enrollment-wizard-tutorPackageTranscriptId-availability",
    path: availabilityYMsA7lxMWzMeta?.path ?? "/bright-horizons/enrollment-wizard/:tutorPackageTranscriptId()/availability",
    meta: availabilityYMsA7lxMWzMeta || {},
    alias: availabilityYMsA7lxMWzMeta?.alias || [],
    redirect: availabilityYMsA7lxMWzMeta?.redirect,
    component: () => import("/vercel/path0/apps/student/src/pages/bright-horizons/enrollment-wizard/[tutorPackageTranscriptId]/availability.vue").then(m => m.default || m)
  },
  {
    name: completeyVKWSLYEyXMeta?.name ?? "bright-horizons-enrollment-wizard-tutorPackageTranscriptId-complete",
    path: completeyVKWSLYEyXMeta?.path ?? "/bright-horizons/enrollment-wizard/:tutorPackageTranscriptId()/complete",
    meta: completeyVKWSLYEyXMeta || {},
    alias: completeyVKWSLYEyXMeta?.alias || [],
    redirect: completeyVKWSLYEyXMeta?.redirect,
    component: () => import("/vercel/path0/apps/student/src/pages/bright-horizons/enrollment-wizard/[tutorPackageTranscriptId]/complete.vue").then(m => m.default || m)
  },
  {
    name: indexiTHLjm61MQMeta?.name ?? "bright-horizons-enrollment-wizard-tutorPackageTranscriptId",
    path: indexiTHLjm61MQMeta?.path ?? "/bright-horizons/enrollment-wizard/:tutorPackageTranscriptId()",
    meta: indexiTHLjm61MQMeta || {},
    alias: indexiTHLjm61MQMeta?.alias || [],
    redirect: indexiTHLjm61MQMeta?.redirect,
    component: () => import("/vercel/path0/apps/student/src/pages/bright-horizons/enrollment-wizard/[tutorPackageTranscriptId]/index.vue").then(m => m.default || m)
  },
  {
    name: subjectDQ8HcoxvHbMeta?.name ?? "bright-horizons-enrollment-wizard-tutorPackageTranscriptId-subject",
    path: subjectDQ8HcoxvHbMeta?.path ?? "/bright-horizons/enrollment-wizard/:tutorPackageTranscriptId()/subject",
    meta: subjectDQ8HcoxvHbMeta || {},
    alias: subjectDQ8HcoxvHbMeta?.alias || [],
    redirect: subjectDQ8HcoxvHbMeta?.redirect,
    component: () => import("/vercel/path0/apps/student/src/pages/bright-horizons/enrollment-wizard/[tutorPackageTranscriptId]/subject.vue").then(m => m.default || m)
  },
  {
    name: submitn47fIJwWWIMeta?.name ?? "bright-horizons-enrollment-wizard-tutorPackageTranscriptId-submit",
    path: submitn47fIJwWWIMeta?.path ?? "/bright-horizons/enrollment-wizard/:tutorPackageTranscriptId()/submit",
    meta: submitn47fIJwWWIMeta || {},
    alias: submitn47fIJwWWIMeta?.alias || [],
    redirect: submitn47fIJwWWIMeta?.redirect,
    component: () => import("/vercel/path0/apps/student/src/pages/bright-horizons/enrollment-wizard/[tutorPackageTranscriptId]/submit.vue").then(m => m.default || m)
  },
  {
    name: tutordyUZEsRV2FMeta?.name ?? "bright-horizons-enrollment-wizard-tutorPackageTranscriptId-tutor",
    path: tutordyUZEsRV2FMeta?.path ?? "/bright-horizons/enrollment-wizard/:tutorPackageTranscriptId()/tutor",
    meta: tutordyUZEsRV2FMeta || {},
    alias: tutordyUZEsRV2FMeta?.alias || [],
    redirect: tutordyUZEsRV2FMeta?.redirect,
    component: () => import("/vercel/path0/apps/student/src/pages/bright-horizons/enrollment-wizard/[tutorPackageTranscriptId]/tutor.vue").then(m => m.default || m)
  },
  {
    name: indexQgbNHFFVWSMeta?.name ?? "exams",
    path: indexQgbNHFFVWSMeta?.path ?? "/exams",
    meta: indexQgbNHFFVWSMeta || {},
    alias: indexQgbNHFFVWSMeta?.alias || [],
    redirect: indexQgbNHFFVWSMeta?.redirect,
    component: () => import("/vercel/path0/apps/student/src/pages/exams/index.vue").then(m => m.default || m)
  },
  {
    name: proctorEC288oreBTMeta?.name ?? "exams-proctor",
    path: proctorEC288oreBTMeta?.path ?? "/exams/proctor",
    meta: proctorEC288oreBTMeta || {},
    alias: proctorEC288oreBTMeta?.alias || [],
    redirect: proctorEC288oreBTMeta?.redirect,
    component: () => import("/vercel/path0/apps/student/src/pages/exams/proctor.vue").then(m => m.default || m)
  },
  {
    name: helptrLlr0zfXMMeta?.name ?? "help",
    path: helptrLlr0zfXMMeta?.path ?? "/help",
    meta: helptrLlr0zfXMMeta || {},
    alias: helptrLlr0zfXMMeta?.alias || [],
    redirect: helptrLlr0zfXMMeta?.redirect,
    component: () => import("/vercel/path0/apps/student/src/pages/help.vue").then(m => m.default || m)
  },
  {
    name: indexW3BdLl1coVMeta?.name ?? "index",
    path: indexW3BdLl1coVMeta?.path ?? "/",
    meta: indexW3BdLl1coVMeta || {},
    alias: indexW3BdLl1coVMeta?.alias || [],
    redirect: indexW3BdLl1coVMeta?.redirect,
    component: () => import("/vercel/path0/apps/student/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: login2u7ddXs1S1Meta?.name ?? "login",
    path: login2u7ddXs1S1Meta?.path ?? "/login",
    meta: login2u7ddXs1S1Meta || {},
    alias: login2u7ddXs1S1Meta?.alias || [],
    redirect: login2u7ddXs1S1Meta?.redirect,
    component: () => import("/vercel/path0/apps/student/src/pages/login.vue").then(m => m.default || m)
  },
  {
    name: materialskh63ZSbPfcMeta?.name ?? "materials",
    path: materialskh63ZSbPfcMeta?.path ?? "/materials",
    meta: materialskh63ZSbPfcMeta || {},
    alias: materialskh63ZSbPfcMeta?.alias || [],
    redirect: materialskh63ZSbPfcMeta?.redirect,
    component: () => import("/vercel/path0/apps/student/src/pages/materials.vue").then(m => m.default || m)
  },
  {
    name: indexlu1AULD9ZUMeta?.name ?? "my-calendar",
    path: indexlu1AULD9ZUMeta?.path ?? "/my-calendar",
    meta: indexlu1AULD9ZUMeta || {},
    alias: indexlu1AULD9ZUMeta?.alias || [],
    redirect: indexlu1AULD9ZUMeta?.redirect,
    component: () => import("/vercel/path0/apps/student/src/pages/my-calendar/index.vue").then(m => m.default || m)
  },
  {
    name: indexhJVoXQ6V7uMeta?.name ?? "profile",
    path: indexhJVoXQ6V7uMeta?.path ?? "/profile",
    meta: indexhJVoXQ6V7uMeta || {},
    alias: indexhJVoXQ6V7uMeta?.alias || [],
    redirect: indexhJVoXQ6V7uMeta?.redirect,
    component: () => import("/vercel/path0/apps/student/src/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: availabilityDGGL2HfUouMeta?.name ?? "schedule-sessions-enrollmentId-availability",
    path: availabilityDGGL2HfUouMeta?.path ?? "/schedule-sessions/:enrollmentId()/availability",
    meta: availabilityDGGL2HfUouMeta || {},
    alias: availabilityDGGL2HfUouMeta?.alias || [],
    redirect: availabilityDGGL2HfUouMeta?.redirect,
    component: () => import("/vercel/path0/apps/student/src/pages/schedule-sessions/[enrollmentId]/availability.vue").then(m => m.default || m)
  },
  {
    name: confirmMdCO0i2YDNMeta?.name ?? "schedule-sessions-enrollmentId-confirm",
    path: confirmMdCO0i2YDNMeta?.path ?? "/schedule-sessions/:enrollmentId()/confirm",
    meta: confirmMdCO0i2YDNMeta || {},
    alias: confirmMdCO0i2YDNMeta?.alias || [],
    redirect: confirmMdCO0i2YDNMeta?.redirect,
    component: () => import("/vercel/path0/apps/student/src/pages/schedule-sessions/[enrollmentId]/confirm.vue").then(m => m.default || m)
  },
  {
    name: subjectsGXRGfU3SaMeta?.name ?? "schedule-sessions-enrollmentId-subject",
    path: subjectsGXRGfU3SaMeta?.path ?? "/schedule-sessions/:enrollmentId()/subject",
    meta: subjectsGXRGfU3SaMeta || {},
    alias: subjectsGXRGfU3SaMeta?.alias || [],
    redirect: subjectsGXRGfU3SaMeta?.redirect,
    component: () => import("/vercel/path0/apps/student/src/pages/schedule-sessions/[enrollmentId]/subject.vue").then(m => m.default || m)
  },
  {
    name: indexl7h7coric4Meta?.name ?? "schedule-sessions",
    path: indexl7h7coric4Meta?.path ?? "/schedule-sessions",
    meta: indexl7h7coric4Meta || {},
    alias: indexl7h7coric4Meta?.alias || [],
    redirect: indexl7h7coric4Meta?.redirect,
    component: () => import("/vercel/path0/apps/student/src/pages/schedule-sessions/index.vue").then(m => m.default || m)
  },
  {
    name: subscribeejtK1G7E6cMeta?.name ?? "schedule-subscribe",
    path: subscribeejtK1G7E6cMeta?.path ?? "/schedule/subscribe",
    meta: subscribeejtK1G7E6cMeta || {},
    alias: subscribeejtK1G7E6cMeta?.alias || [],
    redirect: subscribeejtK1G7E6cMeta?.redirect,
    component: () => import("/vercel/path0/apps/student/src/pages/schedule/subscribe.vue").then(m => m.default || m)
  },
  {
    name: tutorV8OLNODAhgMeta?.name ?? undefined,
    path: tutorV8OLNODAhgMeta?.path ?? "/schedule/tutor",
    meta: tutorV8OLNODAhgMeta || {},
    alias: tutorV8OLNODAhgMeta?.alias || [],
    redirect: tutorV8OLNODAhgMeta?.redirect,
    component: () => import("/vercel/path0/apps/student/src/pages/schedule/tutor.vue").then(m => m.default || m),
    children: [
  {
    name: confirmAJtXXpR2vQMeta?.name ?? "schedule-tutor-tutorId-enrollment-enrollmentId-confirm",
    path: confirmAJtXXpR2vQMeta?.path ?? ":tutorId()/enrollment/:enrollmentId()/confirm",
    meta: confirmAJtXXpR2vQMeta || {},
    alias: confirmAJtXXpR2vQMeta?.alias || [],
    redirect: confirmAJtXXpR2vQMeta?.redirect,
    component: () => import("/vercel/path0/apps/student/src/pages/schedule/tutor/[tutorId]/enrollment/[enrollmentId]/confirm.vue").then(m => m.default || m)
  },
  {
    name: indexfEF1qEF4MhMeta?.name ?? "schedule-tutor-tutorId-enrollment-enrollmentId",
    path: indexfEF1qEF4MhMeta?.path ?? ":tutorId()/enrollment/:enrollmentId()",
    meta: indexfEF1qEF4MhMeta || {},
    alias: indexfEF1qEF4MhMeta?.alias || [],
    redirect: indexfEF1qEF4MhMeta?.redirect,
    component: () => import("/vercel/path0/apps/student/src/pages/schedule/tutor/[tutorId]/enrollment/[enrollmentId]/index.vue").then(m => m.default || m)
  },
  {
    name: successfkQ9CVVJYvMeta?.name ?? "schedule-tutor-tutorId-enrollment-enrollmentId-success",
    path: successfkQ9CVVJYvMeta?.path ?? ":tutorId()/enrollment/:enrollmentId()/success",
    meta: successfkQ9CVVJYvMeta || {},
    alias: successfkQ9CVVJYvMeta?.alias || [],
    redirect: successfkQ9CVVJYvMeta?.redirect,
    component: () => import("/vercel/path0/apps/student/src/pages/schedule/tutor/[tutorId]/enrollment/[enrollmentId]/success.vue").then(m => m.default || m)
  },
  {
    name: indexdclNCTtSA7Meta?.name ?? "schedule-tutor",
    path: indexdclNCTtSA7Meta?.path ?? "",
    meta: indexdclNCTtSA7Meta || {},
    alias: indexdclNCTtSA7Meta?.alias || [],
    redirect: indexdclNCTtSA7Meta?.redirect,
    component: () => import("/vercel/path0/apps/student/src/pages/schedule/tutor/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: secretOhvL8TPAy0Meta?.name ?? "secret",
    path: secretOhvL8TPAy0Meta?.path ?? "/secret",
    meta: secretOhvL8TPAy0Meta || {},
    alias: secretOhvL8TPAy0Meta?.alias || [],
    redirect: secretOhvL8TPAy0Meta?.redirect,
    component: () => import("/vercel/path0/apps/student/src/pages/secret.vue").then(m => m.default || m)
  },
  {
    name: study_45areasKryxwKFdn6Meta?.name ?? "study-areas",
    path: study_45areasKryxwKFdn6Meta?.path ?? "/study-areas",
    meta: study_45areasKryxwKFdn6Meta || {},
    alias: study_45areasKryxwKFdn6Meta?.alias || [],
    redirect: study_45areasKryxwKFdn6Meta?.redirect,
    component: () => import("/vercel/path0/apps/student/src/pages/study-areas.vue").then(m => m.default || m)
  },
  {
    name: availabilitytXLplHGWnCMeta?.name ?? "tutor-matching-matchId-availability",
    path: availabilitytXLplHGWnCMeta?.path ?? "/tutor-matching/:matchId()/availability",
    meta: availabilitytXLplHGWnCMeta || {},
    alias: availabilitytXLplHGWnCMeta?.alias || [],
    redirect: availabilitytXLplHGWnCMeta?.redirect,
    component: () => import("/vercel/path0/apps/student/src/pages/tutor-matching/[matchId]/availability.vue").then(m => m.default || m)
  },
  {
    name: completeAMpuJxapdQMeta?.name ?? "tutor-matching-matchId-complete",
    path: completeAMpuJxapdQMeta?.path ?? "/tutor-matching/:matchId()/complete",
    meta: completeAMpuJxapdQMeta || {},
    alias: completeAMpuJxapdQMeta?.alias || [],
    redirect: completeAMpuJxapdQMeta?.redirect,
    component: () => import("/vercel/path0/apps/student/src/pages/tutor-matching/[matchId]/complete.vue").then(m => m.default || m)
  },
  {
    name: indexU81GS62osBMeta?.name ?? "tutor-matching-matchId",
    path: indexU81GS62osBMeta?.path ?? "/tutor-matching/:matchId()",
    meta: indexU81GS62osBMeta || {},
    alias: indexU81GS62osBMeta?.alias || [],
    redirect: indexU81GS62osBMeta?.redirect,
    component: () => import("/vercel/path0/apps/student/src/pages/tutor-matching/[matchId]/index.vue").then(m => m.default || m)
  },
  {
    name: submit17T7i42Ge0Meta?.name ?? "tutor-matching-matchId-submit",
    path: submit17T7i42Ge0Meta?.path ?? "/tutor-matching/:matchId()/submit",
    meta: submit17T7i42Ge0Meta || {},
    alias: submit17T7i42Ge0Meta?.alias || [],
    redirect: submit17T7i42Ge0Meta?.redirect,
    component: () => import("/vercel/path0/apps/student/src/pages/tutor-matching/[matchId]/submit.vue").then(m => m.default || m)
  },
  {
    name: indexIdhUawRk3EMeta?.name ?? "videos-subject",
    path: indexIdhUawRk3EMeta?.path ?? "/videos/:subject()",
    meta: indexIdhUawRk3EMeta || {},
    alias: indexIdhUawRk3EMeta?.alias || [],
    redirect: indexIdhUawRk3EMeta?.redirect,
    component: () => import("/vercel/path0/apps/student/src/pages/videos/[subject]/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93dycxtJdy2yMeta?.name ?? "videos-subject-watch-id",
    path: _91id_93dycxtJdy2yMeta?.path ?? "/videos/:subject()/watch/:id()",
    meta: _91id_93dycxtJdy2yMeta || {},
    alias: _91id_93dycxtJdy2yMeta?.alias || [],
    redirect: _91id_93dycxtJdy2yMeta?.redirect,
    component: () => import("/vercel/path0/apps/student/src/pages/videos/[subject]/watch/[id].vue").then(m => m.default || m)
  },
  {
    name: indexA65heq9koXMeta?.name ?? "videos",
    path: indexA65heq9koXMeta?.path ?? "/videos",
    meta: indexA65heq9koXMeta || {},
    alias: indexA65heq9koXMeta?.alias || [],
    redirect: indexA65heq9koXMeta?.redirect,
    component: () => import("/vercel/path0/apps/student/src/pages/videos/index.vue").then(m => m.default || m)
  }
]