export const useTrialStore = defineStore('trial', () => {
  // state
  const activeTrialDays = ref(0)
  const isActiveTrial = ref(false)

  return {
    activeTrialDays,
    isActiveTrial
  }
})
