<script setup lang="ts" />

<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.4987 1.66699H4.9987C4.55667 1.66699 4.13275 1.84259 3.82019 2.15515C3.50763 2.46771 3.33203 2.89163 3.33203 3.33366V16.667C3.33203 17.109 3.50763 17.5329 3.82019 17.8455C4.13275 18.1581 4.55667 18.3337 4.9987 18.3337H14.9987C15.4407 18.3337 15.8646 18.1581 16.1772 17.8455C16.4898 17.5329 16.6654 17.109 16.6654 16.667V5.83366L12.4987 1.66699Z"
      stroke="#5E5E5E"
      stroke-width="1.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11.668 1.66699V5.00033C11.668 5.44235 11.8436 5.86628 12.1561 6.17884C12.4687 6.4914 12.8926 6.66699 13.3346 6.66699H16.668"
      stroke="#5E5E5E"
      stroke-width="1.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.33464 7.5H6.66797"
      stroke="#5E5E5E"
      stroke-width="1.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.3346 10.833H6.66797"
      stroke="#5E5E5E"
      stroke-width="1.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.3346 14.167H6.66797"
      stroke="#5E5E5E"
      stroke-width="1.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
