import revive_payload_client_YTGlmQWWT5 from "/vercel/path0/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_neSs9z3UJp from "/vercel/path0/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_VeD7Ba96AP from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_VTarsGAzgb from "/vercel/path0/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_LIYcCMJD18 from "/vercel/path0/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_xKuw1km5sV from "/vercel/path0/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/vercel/path0/apps/student/.nuxt/components.plugin.mjs";
import prefetch_client_8FoqvPFTot from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_jU43rAErqK from "/vercel/path0/packages/pusher/src/runtime/plugin.ts";
import plugin_ZVEKPF4mbi from "/vercel/path0/packages/components/src/runtime/plugin.ts";
import plugin_biYzj5jOQk from "/vercel/path0/packages/actor/src/runtime/plugin.ts";
import pwa_icons_Z9m1jdsncU from "/vercel/path0/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa-icons.mjs";
import pwa_client_V4UTG2qgGR from "/vercel/path0/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import plugin_iusAUtqzOR from "/vercel/path0/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import index_JEsirudAmz from "/vercel/path0/apps/student/src/plugins/error/index.ts";
import reset_store_WZJyv6i6IN from "/vercel/path0/apps/student/src/plugins/pinia/reset-store.ts";
import index_wx1KxVq9Xe from "/vercel/path0/apps/student/src/plugins/repository/index.ts";
import index_client_ZwycNEjhlb from "/vercel/path0/apps/student/src/plugins/userflow/index.client.ts";
import index_VInUTGIsPn from "/vercel/path0/apps/student/src/plugins/sentry/index.ts";
import index_BcxSRh1FTu from "/vercel/path0/apps/student/src/plugins/lottie/index.ts";
export default [
  revive_payload_client_YTGlmQWWT5,
  unhead_neSs9z3UJp,
  router_VeD7Ba96AP,
  payload_client_VTarsGAzgb,
  check_outdated_build_client_LIYcCMJD18,
  plugin_vue3_xKuw1km5sV,
  components_plugin_KR1HBZs4kY,
  prefetch_client_8FoqvPFTot,
  plugin_jU43rAErqK,
  plugin_ZVEKPF4mbi,
  plugin_biYzj5jOQk,
  pwa_icons_Z9m1jdsncU,
  pwa_client_V4UTG2qgGR,
  plugin_iusAUtqzOR,
  index_JEsirudAmz,
  reset_store_WZJyv6i6IN,
  index_wx1KxVq9Xe,
  index_client_ZwycNEjhlb,
  index_VInUTGIsPn,
  index_BcxSRh1FTu
]