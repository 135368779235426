export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig()
  const orbitFetchInstance = createOrbitApiFetchInstance(config.public)
  return {
    provide: {
      // provide orbit resources
      attendances: apiFetchRepository(orbitFetchInstance, 'v2/attendances'),
      authorizeNetProfiles: apiFetchRepository(orbitFetchInstance, 'v2/authorize_net_profiles'),
      avatars: apiFetchRepository(orbitFetchInstance, 'v2/avatars'),
      availabilities: apiFetchRepository(orbitFetchInstance, 'v2/availabilities'),
      availabilityRepeatOptions: apiFetchRepository(orbitFetchInstance, 'v2/availabilities/repeat_options'),
      courseMaterials: apiFetchRepository(orbitFetchInstance, 'v2/course_materials'),
      employees: apiFetchRepository(orbitFetchInstance, 'v2/employees'),
      feedItems: apiFetchRepository(orbitFetchInstance, 'v2/feed_items'),
      limitedEnrollments: apiFetchRepository(orbitFetchInstance, 'v2/limited_enrollments'),
      reup: apiFetchRepository(orbitFetchInstance, 'v2/tutor_package_transcripts/reup'),
      requestSessionTimes: apiFetchRepository(orbitFetchInstance, 'v2/students/request_session_times'),
      reviews: apiFetchRepository(orbitFetchInstance, 'v2/reviews'),
      sessions: apiFetchRepository(orbitFetchInstance, 'v2/sessions'),
      slotSubscriptions: apiFetchRepository(orbitFetchInstance, 'v2/slot_subscriptions'),
      tutorings: apiFetchRepository(orbitFetchInstance, 'v2/tutorings'),
      users: apiFetchRepository(orbitFetchInstance, 'v2/users')
    }
  }
})
